import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry, DesktopRightImage, PhoneImage } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Link className="text-black" to="/destination/france" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/sospel_8.jpg" mdxType="DesktopRightImage" />
        <h2>{`France`}</h2>
        <h4>{`The cycling nation!`}</h4>
        <p>{`Probably no other nation has such a dedicated biking community. From road to
downhill biking, the french riders compete in the highest levels. The
possibilities for mountain biking in France are on an equally high level!`}</p>
        <PhoneImage src="destinations/sospel_8.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/canazei_1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Italy`}</h2>
        <h4>{`Good weather, excellent food, coffee and wine, great mountains and trails. What else do you want?`}</h4>
        <p>{`A big part of the Alps is on Italian territory. From the highest mountain,
the Monte Bianco, to the Ligurian Alps at the Mediterranean sea and all the
way down to Sicily, Italy is very diverse. The excellent coffee and food is
the only constant.`}</p>
        <PhoneImage src="destinations/canazei_1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/norway" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/hardangerfjord_18.jpg" mdxType="DesktopRightImage" />
        <h2>{`Norway`}</h2>
        <h4>{`Fjords, Elks and Trolls`}</h4>
        <p>{`A mystic country with a versatile landscape. From flowy forest trails in the
south, over steep and rocky mountains at the west coast, beautiful islands
and fjords, to the barren landcape in the north.`}</p>
        <PhoneImage src="destinations/hardangerfjord_18.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/switzerland" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/stmoritz_8.jpg" mdxType="DesktopRightImage" />
        <h2>{`Switzerland`}</h2>
        <h4>{`Idyllic valleys, high mountains, long trails and a great infrastructure.`}</h4>
        <p>{`The heart of the alps. The country is almost completely covered with mountains
and you find one beautiful valley next to the other, full of great trails.
Well developed, there are many resorts with lifts bikers can use.`}</p>
        <PhoneImage src="destinations/stmoritz_8.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/slovenia" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/slovenia1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Slovenia`}</h2>
        <h4>{`Mystic, wild, untouched, beautiful nature and a strong bike community.`}</h4>
        <p>{`Not only bears and wolves hide in the woods of Slovenia. When you have a
closer look, you will also find a lot of trails. Not very obvious, as it is
still forbidden to ride in many places. But the situation improves and
the potential is huge.`}</p>
        <PhoneImage src="destinations/slovenia1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/spain" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/benasque_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`Spain`}</h2>
        <h4>{`Destinations for every time of the year`}</h4>
        <p>{`You like rocks, dry trails, riding close to the sea, tasty meat and a
friendly and relaxed culture? Then you should go to Spain!`}</p>
        <PhoneImage src="destinations/benasque_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/germany" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/sasbachwalden1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Germany`}</h2>
        <h4>{`Underestimated, with lots of hidden gems`}</h4>
        <p>{`Parts of the alps are in the south of Germany. But most of the mountain
biking is in the lower ranges called Mittelgebirge. You don’t need steep
mountains for great biking and there is plenty of it all over Germany.`}</p>
        <PhoneImage src="destinations/sasbachwalden1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/czechrepublic" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/klinovec_2.jpg" mdxType="DesktopRightImage" />
        <h2>{`Czech Republic`}</h2>
        <h4>{`Wild east`}</h4>
        <p>{`The Czech Republic shares most of it's mountain ranges with other
countries as for example Germany. The Erzgebirge is a Mittlegebirge
with lots of forest and trails to discover.`}</p>
        <PhoneImage src="destinations/klinovec_2.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      